@import '../../../styles/settings.scss';

.quantity {
	&__wrapper {
		display: flex;
		align-items: center;
		gap: 1rem;
		width: 100%;
	}

	&__view {
		width: 2rem;
		text-align: center;
		border: solid 0.063rem $black55;

		p {
			font-size: 1.266rem;
		}
	}

	&__input {
		opacity: 0.55;
		cursor: pointer;

		img {
			display: block;
			width: 1.5rem;
			height: 100%;
		}
	}
}

@media screen and (max-width: 600px) {
	.quantity {
		&__wrapper {
			gap: 2rem;
		}

		&__view {
			p {
				font-size: 1rem;
			}
		}
	}
}
