@import '../../../styles/settings.scss';

.scrollButton {
	&__wrapper {
		display: flex;
		justify-content: flex-start;
		margin-top: 1rem;
	}

	&__frame {
		position: relative;
		width: 1.2rem;
		height: 2.4rem;
		border: solid 0.063rem $white;
		border-radius: 1rem;
		background-color: transparent;
	}

	&__icon {
		position: absolute;
		top: 0.1rem;
		left: 50%;
		width: 0.6rem;
		height: 0.6rem;
		background-color: $white;
		border-radius: 50%;
		animation: scroll 1.85s ease infinite;
	}

	@keyframes scroll {
		0% {
			transform: translateX(-50%) translateY(0);
			opacity: 0;
		}
		50% {
			opacity: 0.9;
		}
		100% {
			transform: translateX(-50%) translateY(1.55rem);
			opacity: 0;
		}
	}
}

@media screen and (max-width: 600px) {
	.scrollButton {
		&__wrapper {
			margin-top: 1.5rem;
			justify-content: center;
		}
	}
}
