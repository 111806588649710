@import '../../../styles/settings.scss';

.footer {
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		padding: 8rem 3rem 1rem 3rem;
	}

	&__body {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: 1fr;
		justify-content: space-between;

		&__item {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			&__title {
				&__icon {
					display: none;
				}
			}

			&__list {
				&__inner {
					display: flex;
					flex-direction: column;
					gap: 1rem;
					pointer-events: none;

					& > * {
						pointer-events: auto;
						transition: opacity 0.6s $sine-out;
					}

					&:hover > * {
						opacity: 0.4;
					}

					&:hover > *:hover {
						opacity: 1;
					}

					&__link {
						a {
							font-size: 0.889rem;
							color: $black75;
						}
					}
				}
			}
		}
	}

	&__bottom {
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		&__socials {
			grid-column: 4 / 5;
		}
	}

	&__copyright {
		width: 100%;
		text-align: center;

		p {
			font-size: 0.79rem;
			color: $black55;
		}
	}
}

@media screen and (max-width: 1200px) {
	.footer {
		&__wrapper {
			padding: 6rem 2rem 1rem 2rem;
		}

		&__body {
			display: flex;
			flex-direction: column;

			&__item {
				gap: 0.5rem;

				&__title {
					padding-top: 0.5rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;

					&__icon {
						display: block;

						img {
							display: block;
							width: 1rem;
							height: 100%;
							transform: rotate(0);
							transition: transform 0.3s $sine-out;

							&.rotate {
								transform: rotate(180deg);
							}
						}
					}
				}

				&__list {
					display: grid;
					grid-template-rows: 0fr;
					padding-left: 0.5rem;
					transition: grid-template-rows 0.3s $sine-out;

					& > ul {
						overflow: hidden;
					}

					&.list-open {
						grid-template-rows: 1fr;
					}

					&__inner {
						transform: translateY(1rem);
						opacity: 0;
						transition:
							transform 0.3s $sine-out,
							opacity 0.3s $sine-out;

						&.open {
							opacity: 1;
							transform: translateY(0);
						}
					}
				}
			}
		}

		&__bottom {
			display: block;
		}
	}
}

@media screen and (max-width: 600px) {
	.footer {
		&__wrapper {
			padding: 4rem 1rem 1rem 1rem;
		}
	}
}
