@import '../../../styles/settings.scss';

.productTabs {
	&__wrapper {
		display: flex;
		flex-direction: column;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		gap: 0.5rem;
		margin-bottom: -0.075rem;

		&__button {
			width: 50%;
			padding: 0.5rem 0 1rem 0;
			text-align: center;
			border: solid 0.063rem $black25;
			border-bottom: none;
			background-color: $black25;
			opacity: 0.4;
			box-shadow: $shadow-inset;
			cursor: pointer;
			transition:
				background-color 0.15s $sine-out,
				opacity 0.15s $sine-out,
				box-shadow 0.15s $sine-out;

			&.active {
				opacity: 1;
				background-color: $white;
				z-index: 3;
				box-shadow: $shadow-top;
			}
		}
	}

	&__body {
		border: solid 0.063rem $black25;
		background-color: $white;
		padding: 3rem;
		z-index: 2;

		&__tab {
			display: none;

			&.active {
				display: block;
			}

			&__features {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, auto);
				gap: 2rem;
			}
		}
	}
}

.tab__title {
	font-weight: 300;
	text-decoration: underline;
	text-underline-offset: 0.5rem;
	margin-bottom: 1rem;
}

.shipping__info {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	&__tab {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
}

@media screen and (max-width: 1200px) {
	.productTabs {
		&__header {
			gap: 0.25rem;
		}
	}
}

@media screen and (max-width: 600px) {
	.productTabs {
		&__header {
			h6 {
				font-size: 0.889rem;
			}
		}

		&__body {
			padding: 1rem;

			h6,
			p {
				font-size: 0.889rem;
			}
		}
	}

	.shipping__info {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		&__tab {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}
	}
}
