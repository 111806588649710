@import '../../../styles/settings.scss';

.register__wrapper {
	min-height: 100vh;
	width: 31rem;
	background-color: $white;
	position: fixed;
	top: 0;
	right: 0;
}

@media screen and (max-width: 600px) {
	.register__wrapper {
		width: 100%;
	}
}
