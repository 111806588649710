.loader {
	&__wrapper {
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		z-index: 999;
		background-color: rgb(250, 250, 250);
		opacity: 0;
		visibility: hidden;
		transition:
			opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),
			visibility 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);

		&.loading {
			visibility: visible;
			opacity: 1;
		}
	}

	&__title {
		display: flex;

		p {
			font-size: 2rem;
			font-weight: 900;
			color: rgb(51, 51, 51);
		}
	}
}
