@import '../../../styles/settings.scss';

.homeAbout {
	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: 50vh;
	}

	&__image {
		width: 50%;
		height: 100%;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			height: 75vh;
			object-fit: cover;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 50%;

		&__inner {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			width: 50%;

			h2 {
				font-weight: 900;
				font-size: 2.027rem;
			}

			&:hover {
				.homeAbout__action {
					p:last-of-type {
						text-decoration-color: $black55;
					}
				}
			}
		}
	}

	&__action {
		display: flex;
		align-items: center;
		gap: 1rem;

		button {
			font-family: $font;
			font-size: 0.889rem;
			font-weight: 800;
			color: $black75;
			border: solid 0.063rem $mint;
			background-color: $mint;
			padding: 0.5rem 2.5rem;
			box-shadow: $shadow-light;
			cursor: pointer;
			transition:
				background-color 0.3s $sine-out,
				border 0.3s $sine-out;

			&:hover {
				background-color: $white;
				border: solid 0.063rem $black75;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.homeAbout {
		&__info {
			&__inner {
				gap: 0.5rem;
				width: 70%;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.homeAbout {
		&__container {
			flex-direction: column-reverse;
			gap: 2rem;
		}

		&__info {
			width: 100%;
			padding: 0 1rem;

			&__inner {
				align-items: flex-start;
				width: 100%;

				h2 {
					font-size: 1.802rem;
				}
			}
		}

		&__image {
			width: 100%;
		}

		&__action {
			padding-top: 0.5rem;
		}
	}
}
