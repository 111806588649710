@import '../../../styles/settings.scss';

.homeSocial {
	&__inner {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		padding: 0 3rem;

		&__header {
			display: flex;
			align-items: center;
			gap: 1rem;

			img {
				display: block;
				width: 1.5rem;
				height: 100%;
			}

			a {
				font-size: 1.266rem;
				font-weight: 300;
				color: $mint70;
				text-decoration: underline 0.063rem rgba(0, 0, 0, 0);
				text-underline-offset: 0.5rem;
				transition: text-decoration-color 0.3s $sine-out;

				&:hover {
					text-decoration-color: $mint80;
				}
			}
		}

		&__images {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);
			gap: 1rem;

			&__box {
				position: relative;
				width: 100%;
				box-shadow: $shadow-medium;
				overflow: hidden;
				cursor: pointer;

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					filter: brightness(0.9);
				}

				&__icon {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					opacity: 0;
					visibility: hidden;
					z-index: 2;
					transition:
						opacity 0.3s $sine-out,
						visibility 0.3s $sine-out,
						transform 0.3s $sine-out;

					img {
						width: 2rem;
						height: 100%;
						filter: invert(1);
						opacity: 0.9;
					}

					&:hover {
						transform: scale(1.025) translateY(-50%) translateX(-50%);
					}
				}

				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.4);
					opacity: 0;
					visibility: hidden;
					z-index: 1;
					transition:
						opacity 0.3s $sine-out,
						visibility 0.3s $sine-out;
				}

				&:hover {
					&.homeSocial__inner__images__box::after {
						visibility: visible;
						opacity: 1;
					}

					.homeSocial__inner__images__box__icon {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}
	}

	&__modal {
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 99;
		visibility: hidden;
		opacity: 0;

		&.active {
			visibility: visible;
			opacity: 1;
		}
	}
}

@media screen and (max-width: 1200px) {
	.homeSocial {
		&__inner {
			padding: 0 2rem;
		}
	}
}

@media screen and (max-width: 600px) {
	.homeSocial {
		&__inner {
			gap: 1rem;
			padding: 0 1rem;

			&__images {
				grid-template-columns: 1fr;
				grid-template-rows: repeat(4, 1fr);
			}
		}
	}
}
