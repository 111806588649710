//  TRANSITIONS
$sine-in: cubic-bezier(0.47, 0, 0.745, 0.715);
$sine-out: cubic-bezier(0.39, 0.575, 0.565, 1);

// SHADOWS
$shadow-light:
	rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
	rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
$shadow-medium:
	rgba(0, 0, 0, 0.16) 0px 3px 6px,
	rgba(0, 0, 0, 0.23) 0px 3px 6px;
$shadow-top: rgba(0, 0, 0, 0.35) 0 -5px 15px -12.5px;
$shadow-inset: rgba(0, 0, 0, 0.2) 0px -50px 36px -28px inset;

//  FONT
$font: 'proxima-nova', sans-serif;

//  PALLETTE
$mint: hsl(111, 11%, 90%);
$mint80: hsl(111, 11%, 78.75%);
$mint70: hsl(111, 11%, 67.5%);
$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 20%);
$black75: hsl(0, 0%, 30%);
$black55: hsl(0, 0%, 55%);
$black25: hsl(0, 0%, 90%);
$black5: hsl(0, 0%, 98%);
$error: hsl(0, 70%, 50%);
