@import '../../../styles/settings.scss';

.about {
	&__wrapper {
		display: flex;
		flex-direction: column;
		padding-top: 8rem;
	}

	&__top__inner,
	&__bottom__inner {
		width: 50rem;
	}

	&__top {
		display: flex;
		justify-content: center;
		margin-bottom: -4rem;
		z-index: 3;

		&__inner {
			padding: 3rem;
			background-color: $mint;
			box-shadow: $shadow-light;

			h1 {
				font-size: 4rem;
				font-weight: 900;
				color: $white;
				line-height: 1;
				text-transform: uppercase;
				margin-bottom: 3rem;
			}

			&__info {
				display: flex;
				flex-direction: column;
				gap: 1rem;
			}

			&__button {
				margin-top: 3rem;

				a {
					font-family: $font;
					font-size: 2.027rem;
					font-weight: 900;
					color: $black75;
					background: transparent;
					border: solid 0.063rem $black75;
					padding: 0.5rem 2rem;
					cursor: pointer;
					transition:
						color 0.3s $sine-out,
						border 0.3s $sine-out;

					&:hover {
						color: $white;
						border: solid 0.063rem $white;
					}
				}
			}
		}
	}

	&__bottom {
		height: 100vh;
		position: relative;

		&__background {
			position: relative;
			height: 100%;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
			}
		}

		&__inner {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			padding-top: 7rem;
			background-color: transparent;
			z-index: 2;

			&__grid {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 1rem;

				&__box {
					display: grid;
					grid-template-rows: 800% 1fr 1fr;

					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
						filter: brightness(0.9);
					}
				}
			}

			h2,
			h6,
			p {
				color: $white;
			}

			h2 {
				font-weight: 300;
				line-height: 1.2;
				text-transform: uppercase;
				width: 75%;
				margin-bottom: 2rem;
			}

			h6 {
				text-transform: uppercase;
				margin: 0.5rem 0;
			}

			p {
				font-size: 0.889rem;
			}
		}
	}
}

@media screen and (max-width: 1200px) and (orientation: portrait) {
	.about {
		&__wrapper {
			padding-top: 6rem;
		}

		&__top__inner,
		&__bottom__inner {
			width: 70vw;
		}

		&__top__inner {
			padding: 2rem;

			h1 {
				font-size: 6vw;
				margin-bottom: 2rem;
			}
		}

		&__bottom {
			height: 100vh;

			&__inner {
				&__grid {
					&__box {
						grid-template-rows: 250% 1fr 1fr;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
	.about {
		&__wrapper {
			padding-top: 6rem;
		}

		&__top__inner,
		&__bottom__inner {
			width: 70vw;
		}

		&__top__inner {
			padding: 2rem;

			h1 {
				font-size: 6vw;
				margin-bottom: 2rem;
			}
		}

		&__bottom {
			height: 200vh;

			&__inner {
				&__grid {
					&__box {
						grid-template-rows: 175% 1fr 1fr;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.about {
		&__wrapper {
			padding-top: 8rem;
			margin-bottom: -4rem;
		}

		&__top__inner,
		&__bottom__inner {
			width: 90vw;
		}

		&__top__inner {
			padding: 1rem 1rem 2rem 1rem;

			h1 {
				font-size: 8vw;
				margin-bottom: 1rem;
			}

			&__button {
				a {
					display: block;
					font-size: 1.602rem;
					text-align: center;
				}
			}

			&__info {
				p {
					font-size: 0.889rem;
				}
			}
		}

		&__bottom {
			height: 200vh;

			&__inner {
				h2 {
					width: 100%;
					font-size: 1.424rem;
				}

				&__grid {
					&__box {
						grid-template-rows: 150% 1fr 1fr;
					}
				}
			}
		}
	}
}
