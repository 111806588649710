@import './settings.scss';

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	background-color: $white;
	font-family: $font;
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	-ms-overflow-style: none;
	scrollbar-width: 0;
	cursor: default;

	&::-webkit-scrollbar {
		display: none;
	}
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $black;
	line-height: 1.6;
	margin: 0;
	padding: 0;
}

h1 {
	font-size: 2.027rem;
}

h2 {
	font-size: 1.802rem;
}

h3 {
	font-size: 1.602rem;
}

h4 {
	font-size: 1.424rem;
}

h5 {
	font-size: 1.266rem;
}

h6 {
	font-size: 1.125rem;
}

p {
	font-size: 1rem;
	color: $black75;
}

a {
	text-decoration: none;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
