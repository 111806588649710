@import '../../../styles/settings.scss';

.homeBrands {
	&__wrapper {
		padding: 1rem 0;
	}

	&__box {
		display: flex !important;
		justify-content: center;
		align-items: center;

		img {
			display: block;
			width: 10rem;
			height: 10rem;
			object-fit: contain;
			transition: transform 0.3s $sine-out;

			&:hover {
				transform: scale(1.035);
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.homeBrands {
		&__box {
			img {
				width: 7rem;
				height: 7rem;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.homeBrands {
		&__box {
			img {
				width: 5rem;
				height: 5rem;
			}
		}
	}
}
