@import '../../../styles/settings.scss';

.productModal {
	&__wrapper {
		position: relative;
		width: 100%;
		height: 100%;
	}

	&__image {
		width: 100%;
		height: 100%;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__navigation {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		padding: 0.5rem 1rem;
		background-color: $white;
		box-shadow: $shadow-light;

		&__button {
			transition: transform 0.3s $sine-out;
			cursor: pointer;

			img {
				display: block;
				width: 1.5rem;
				height: 100%;
				transition: filter 0.3s $sine-out;
			}

			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.productModal {
		&__navigation {
			&__button {
				img {
					width: 1.25rem;
				}
			}
		}
	}
}
