@import '../../../styles/settings.scss';

.arrow-prev:before {
	content: '\27F5';
}

.arrow-next:before {
	content: '\27F6';
}

.arrow-prev,
.arrow-next {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	top: 50%;
	z-index: 99;
	cursor: pointer;

	&::before {
		color: $black;
		padding: 0 1.5rem 0.25rem 1.5rem;
		font-size: 1.5rem;
		border: solid 0.063rem $black;
	}
}

.arrow-prev {
	left: 2rem;
	transform: translateY(-50%);

	&:hover {
		transform: translateX(-5%) translateY(-50%);
	}
}

.arrow-next {
	right: 2rem;
	transform: translateY(-50%);

	&:hover {
		transform: translateX(5%) translateY(-50%);
	}
}

.slick-slider {
	&:hover {
		.arrow-prev,
		.arrow-next {
			transition:
				opacity 0.3s $sine-out,
				visibility 0.3s $sine-out,
				transform 0.3s $sine-out;
			visibility: visible;
			opacity: 1;
		}
	}
}
