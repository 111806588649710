@import '../../../styles/settings.scss';

.productGallery {
	&__wrapper {
		display: grid;
		grid-template-columns: 23.5% 75%;
		grid-template-rows: 100%;
		justify-content: space-between;
		width: 50%;
	}

	&__list {
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: repeat(4, 24%);
		align-content: space-between;

		&__item {
			border: solid 0.063rem;
			border-color: $white;
			background-color: $white;
			box-shadow: $shadow-medium;
			cursor: pointer;
			transition: border-color 0.3s $sine-out;

			&.active {
				border-color: $black75;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	&__show {
		position: relative;
		border: solid 0.063rem $white;
		background-color: $white;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&__loupe {
			position: absolute;
			bottom: 1rem;
			right: 1rem;
			background-color: $white;
			border-radius: 50%;
			box-shadow: $shadow-light;
			padding: 0.15rem;
			cursor: pointer;
			transition: background-color 0.3s $sine-out;

			img {
				display: block;
				width: 2rem;
				height: 100%;
				transition: filter 0.3s $sine-out;
			}

			&:hover {
				background-color: $black;

				img {
					filter: invert(1);
				}
			}
		}
	}

	&__productModal {
		position: fixed;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: 25%;
		border: solid 0.063rem $black75;
		opacity: 0;
		visibility: hidden;
		transition:
			opacity 0.3s $sine-out,
			visibility 0.3s $sine-out;
		z-index: 999;

		&.active {
			visibility: visible;
			opacity: 1;
		}

		&__inner {
			width: 100%;
			height: 100%;
			background-color: $white;
		}
	}
}

@media screen and (max-width: 1200px) {
	.productGallery {
		&__wrapper {
			width: 100%;
		}

		&__productModal {
			&__inner {
				width: 100%;
				height: 100%;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.productGallery {
		&__wrapper {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			gap: 1rem;
		}

		&__list {
			grid-template-columns: repeat(4, 24%);
			grid-template-rows: 100%;
			justify-content: space-between;
		}

		&__show {
			&__loupe {
				img {
					width: 1.5rem;
				}
			}
		}
	}
}
