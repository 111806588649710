@import '../../../styles/settings.scss';

.socialModal {
	&__wrapper {
		display: flex;
		width: 78vw;
		height: 80vh;
		background-color: white;
		border-radius: 1rem;
		overflow: hidden;
		z-index: 900;
		box-shadow: $shadow-medium;
	}

	&__left {
		width: 65%;

		img {
			display: block;
			object-fit: cover;
			width: 100%;
			height: 100%;
			border-top-left-radius: 1rem;
			border-bottom-left-radius: 1rem;
		}
	}

	&__right {
		width: 35%;
		display: flex;
		flex-direction: column;
		gap: 1rem;

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1rem;
			border-bottom: solid 0.063rem $black25;

			&__link {
				display: flex;
				align-items: center;
				gap: 1rem;

				&__icon {
					padding: 0.5rem;
					border: solid 0.063rem $black25;
					border-radius: 50%;
					width: 3rem;
					height: 3rem;

					img {
						width: 100%;
						height: 100%;
					}
				}

				a {
					font-size: 1.25rem;
					font-weight: 700;
					color: $black;
				}
			}

			&__button {
				cursor: pointer;

				img {
					display: block;
					width: 1.5rem;
					height: 100%;
				}
			}

			p {
				font-size: 1.2rem;
				opacity: 0.5;
			}
		}

		&__nav {
			display: flex;
			justify-content: space-between;
			padding: 1rem;

			img {
				width: 1rem;
				height: 100%;
				cursor: pointer;
			}
		}

		&__desc {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			overflow-wrap: break-word;

			&__info {
				display: flex;
				flex-direction: column;
				gap: 3rem;
				padding: 0 2rem;
			}

			&__footer {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				padding: 0.5rem;
				border-top: solid 0.063rem $black25;

				p {
					font-size: 0.688rem;
				}

				a {
					font-size: 0.688rem;
					color: $black;
				}
			}
		}
	}
}

.social__mobile {
	display: none;
}

@media screen and (max-width: 1200px) {
	.socialModal {
		&__wrapper {
			width: 92vw;
			height: 90vh;
		}

		&__left {
			width: 100%;

			img {
				border-radius: 0;
			}
		}

		&__right {
			gap: 1.5rem;
			width: 100%;

			&__nav {
				padding: 0 1rem;
			}

			&__desc {
				&__footer {
					padding: 0.5rem 1rem;
				}
			}
		}
	}

	.social__desktop {
		display: none;
	}

	.social__mobile {
		display: block;
	}
}

@media screen and (max-width: 600px) {
	.socialModal {
		&__wrapper {
			width: 93vw;
			height: 95vh;
		}

		&__right {
			&__header {
				&__link {
					&__icon {
						padding: 0.3rem;
						width: 2rem;
						height: 2rem;
					}
				}
			}

			&__nav {
				padding: 0 0.5rem;

				img {
					width: 0.75rem;
				}
			}

			&__desc {
				&__info {
					gap: 2rem;
					padding: 0 1rem;

					p {
						font-size: 0.75rem;
					}
				}
			}
		}
	}
}
