@import '../../../styles/settings.scss';

.homeBenefit {
	&__wrapper {
		display: flex;
		align-items: center;
		padding: 1rem 3rem;
		background-color: $white;
		z-index: 3;
	}

	&__box {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1.5rem;
		text-align: center;

		img {
			width: 3rem;
			height: 100%;
		}
	}
}

@media screen and (max-width: 1200px) {
	.homeBenefit {
		&__wrapper {
			padding: 1rem 2rem;
		}

		&__box {
			gap: 1rem;

			img {
				width: 2rem;
			}

			h5 {
				font-size: 1.125rem;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.homeBenefit {
		&__wrapper {
			flex-direction: column;
			gap: 3rem;
			padding: 1rem 1rem;
		}
	}
}
