@import '../../../styles/settings.scss';

.productCard {
	&__box {
		&__inner {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			padding: 1rem;
			margin: 1rem;
			background-color: $white;
			cursor: pointer;
			transition: transform 0.3s $sine-out;

			&__image {
				&__images {
					&__container {
						position: sticky;
						top: 2rem;
						width: 55%;
					}

					img {
						width: 100%;
						max-height: 65vh;
						object-fit: contain;
					}

					&:focus {
						outline: none;
					}
				}
			}

			&__info {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				width: 100%;

				&__title {
					width: 75%;
				}

				&__bottom {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				&__buttons {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					z-index: 3;

					&__cart,
					&__wishlist {
						width: 1.75rem;

						img {
							display: block;
							width: 100%;
							height: 100%;
							opacity: 0.6;
							transition: transform 0.3s $sine-out;

							&:hover {
								transform: scale(1.035);
							}
						}
					}
				}

				p {
					font-size: 0.889rem;
				}
			}

			&:hover {
				transform: scale(1.025);
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.productCard {
		&__box {
			&__inner {
				&__info {
				}
			}
		}
	}
}
