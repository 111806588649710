@import '../../../styles/settings.scss';

.social__wrapper {
	width: 100%;
	padding: 1rem 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1.5rem;
	border-bottom: solid 0.063rem $black25;

	a {
		width: 100%;
		text-align: center;

		img {
			width: 1rem;
			height: 100%;
		}
	}
}
