.productBrand {
	&__wrapper {
		position: relative;
	}

	&__inner {
		display: flex;

		&__info {
			display: flex;
			align-items: center;
			width: 50%;

			&__inner {
				width: 75%;

				h2 {
					text-transform: uppercase;
				}
			}
		}

		&__image {
			width: 50%;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.productBrand {
		&__inner {
			flex-direction: column-reverse;
			gap: 2rem;

			&__info {
				width: 100%;

				&__inner {
					width: 95%;
				}
			}

			&__image {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.productBrand {
		&__inner {
			gap: 1rem;

			&__info {
				&__inner {
					h2 {
						font-size: 1.424rem;
					}

					p {
						font-size: 0.889rem;
					}
				}
			}
		}
	}
}
