@import '../../../styles/settings.scss';

.cartModal {
	&__wrapper {
		position: fixed;
		top: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 31rem;
		height: 100dvh;
		padding: 1.4rem 2rem;
		background-color: $white;
	}

	&__header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;
		height: 4.75rem;
		border-bottom: solid 0.063rem $black25;

		&__button {
			cursor: pointer;

			img {
				display: block;
				width: 1.5rem;
				height: 100%;
			}
		}

		a {
			font-size: 1.563rem;
			font-weight: 700;
			color: $black;
		}
	}

	&__bag {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 2rem;
		height: 100%;
		padding: 1rem 0;
		overflow-y: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		&__items {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
		}

		&__images {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			&__inner {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 1rem;

				&__img {
					position: relative;

					&__link {
						position: absolute;
						bottom: 1.5rem;
						left: 50%;
						transform: translateX(-50%);
						display: flex;
						flex-direction: column;
						gap: 4rem;
						width: 100%;
						text-align: center;

						p {
							color: $white;
							font-weight: 300;
						}

						a {
							color: $white;
							text-decoration: underline;
							text-underline-offset: 0.5rem;
						}
					}

					img {
						width: 100%;
						object-fit: cover;
						filter: brightness(0.8);
					}
				}
			}

			h6 {
				font-weight: 400;
			}
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		&__free {
			border-top: solid 0.063rem $black25;
			border-bottom: solid 0.063rem $black25;
			padding: 1rem 0;
			text-align: center;
		}

		&__checkout {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			&__total {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			button {
				padding: 1rem 0;
				font-family: $font;
				font-size: 1rem;
				font-weight: 400;
				color: $white;
				border: solid 0.063rem $black;
				background-color: $black;
				opacity: 0.5;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.cartModal {
		&__wrapper {
			width: 100%;
			padding: 1.3rem 1rem;
		}

		&__header {
			height: 2.5rem;
			padding-bottom: 1rem;
		}
	}
}
