@import '../../../styles/settings.scss';

.homeStaff {
	&__wrapper {
		padding: 8rem 1rem 0 1rem;
		background-color: $white;
		padding-top: 8rem;
	}

	&__title {
		display: flex;
		align-items: center;
		gap: 1rem;
		padding-left: 2rem;

		a {
			font-size: 1.266rem;
			font-weight: 300;
			color: $mint70;
			text-decoration: underline 0.063rem rgba(0, 0, 0, 0);
			text-underline-offset: 0.5rem;
			transition: text-decoration-color 0.3s $sine-out;

			&:hover {
				text-decoration-color: $mint80;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.homeStaff {
		&__wrapper {
			padding: 6rem 0 1.5rem 0;
		}

		&__title {
			padding-left: 2rem;
		}
	}
}

@media screen and (max-width: 600px) {
	.homeStaff {
		&__wrapper {
			padding: 4rem 0 1.5rem 0;
		}

		&__title {
			padding-left: 1rem;
		}
	}
}
