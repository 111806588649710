@import '../../../styles/settings.scss';

.homeGrid {
	&__container {
		display: flex;
		height: 150vh;
	}

	&__info {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50%;
		height: 50%;
		background-color: $white;
		z-index: 2;

		&__inner {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			width: 50%;

			p:last-of-type {
				margin-top: 0.5rem;
				text-decoration: underline 0.063rem rgba(0, 0, 0, 0);
				text-underline-offset: 0.5rem;
				transition: text-decoration-color 0.3s $sine-out;
			}
		}

		&:hover {
			.homeGrid__info__inner {
				p:last-of-type {
					text-decoration-color: $black;
				}
			}
		}
	}

	&__image {
		width: 50%;
		height: 100%;
		z-index: -1;

		img {
			display: block;
			position: sticky;
			top: 0;
			width: 100%;
			height: 50%;
			object-fit: cover;
		}
	}
}

.half__bottom {
	margin-top: -75vh;
}

@media screen and (max-width: 1200px) {
	.homeGrid {
		&__info {
			&__inner {
				p:last-of-type {
					text-decoration-color: $black;
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.homeGrid {
		&__container {
			flex-direction: column-reverse;
			position: relative;
		}

		&__info {
			width: 100%;
			position: absolute;
			bottom: 0;

			&__inner {
				width: 100%;
				padding: 0 1rem;
			}
		}

		&__image {
			width: 100%;
			height: 100%;
		}
	}

	.half__bottom {
		margin-top: 0;
	}
}
