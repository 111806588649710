@import '../../../styles/settings.scss';

.store {
	&__wrapper {
		padding-top: 8rem;
	}
}

@media screen and (max-width: 1200px) {
	.store {
		&__wrapper {
			padding-top: 6rem;
			margin-bottom: -6rem;
		}
	}
}

@media screen and (max-width: 600px) {
	.store {
		&__wrapper {
			padding-top: 7rem;
			margin-bottom: -4rem;
		}
	}
}
