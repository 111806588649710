@import '../../../styles/settings.scss';

.homeHalf {
	&__wrapper {
		display: flex;
		margin-top: -75vh;
		height: 75vh;
	}

	&__tile {
		position: relative;
		width: 50%;
		overflow: hidden;
		z-index: 2;

		box-shadow: $shadow-medium;

		&__info {
			position: absolute;
			bottom: 3rem;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			z-index: 3;

			p,
			h5 {
				color: $white;
			}

			p:last-of-type {
				text-decoration: underline 0.063rem rgba(0, 0, 0, 0);
				text-underline-offset: 0.5rem;
				transition: text-decoration-color 0.3s $sine-out;
			}
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.25);
			transition: background-color 0.3s $sine-out;
		}

		&:hover {
			&::after {
				background-color: rgba(0, 0, 0, 0.4);
			}

			.homeHalf__tile__info {
				p:last-of-type {
					text-decoration-color: $white;
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.homeHalf {
		&__wrapper {
			flex-direction: column;
			height: 100vh;
		}

		&__tile {
			width: 100%;
		}
	}
}

@media screen and (max-width: 600px) {
	.homeHalf {
		&__wrapper {
			height: auto;
			margin-top: 0;
		}
	}
}
