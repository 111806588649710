@import '../../../styles/settings.scss';

.loginModal {
	&__wrapper {
		position: fixed;
		top: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 31rem;
		height: 100dvh;
		padding: 1.4rem 2rem;
		background-color: $white;
	}

	&__nav {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 3rem;
		height: 90dvh;

		&__scroll {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 3rem;
			padding-bottom: 1rem;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&__header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;

			&__button {
				cursor: pointer;

				img {
					display: block;
					width: 1.5rem;
					height: 100%;
				}
			}
		}

		&__form {
			display: flex;
			flex-direction: column;
			width: 100%;

			input {
				width: 100%;
				font-family: $font;
				font-size: 1rem;
				padding: 1rem 0;
				background-color: transparent;
				border: none;
				border-bottom: solid 0.063rem $black75;
				outline: none;

				&::placeholder {
					opacity: 0.6;
				}
			}

			button {
				font-family: $font;
				font-size: 1rem;
				font-weight: 800;
				color: $black75;
				padding: 1rem;
				margin: 2.5rem 0.5rem 0 0.5rem;
				background-color: $mint;
				border: solid 0.063rem $mint;
				box-shadow: $shadow-light;
				cursor: pointer;
				transition:
					background-color 0.3s $sine-out,
					border 0.3s $sine-out;

				&:hover {
					background-color: $white;
					border: solid 0.063rem $black75;
				}
			}
		}

		&__footer {
			display: flex;
			flex-direction: column;
			gap: 1.5rem;

			&__list {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
			}

			a {
				font-size: 1rem;
				color: $black;
				text-decoration: underline;
				text-underline-offset: 0.5rem;
			}

			button {
				font-family: $font;
				font-size: 1rem;
				font-weight: 800;
				color: $black75;
				padding: 1rem;
				margin: 2.5rem 0.5rem 0 0.5rem;
				background-color: transparent;
				border: solid 0.063rem $black75;
				box-shadow: $shadow-light;
				cursor: pointer;
				transition:
					background-color 0.3s $sine-out,
					border 0.3s $sine-out;

				&:hover {
					background-color: $mint;
					border: solid 0.063rem $mint;
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.loginModal {
		&__wrapper {
			width: 100%;
			padding: 1.3rem 1rem;
		}

		&__nav {
			&__header {
				&__button__down {
					transform: translateY(-0.05rem) rotate(-45deg);
				}
			}
		}
	}
}
