@import '../../../styles/settings.scss';

.products {
	&__wrapper {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		justify-content: space-between;
		width: 100%;
		padding: 0 2rem;
		min-height: 100vh;
	}
}

@media screen and (max-width: 1921px) {
	.products {
		&__wrapper {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}

@media screen and (max-width: 1441px) {
	.products {
		&__wrapper {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

@media screen and (max-width: 1200px) {
	.products {
		&__wrapper {
			grid-template-columns: repeat(2, 1fr);
			padding: 0 1rem;
		}
	}
}

@media screen and (max-width: 600px) {
	.products {
		&__wrapper {
			display: flex;
			flex-direction: column;
			padding: 0;
		}
	}
}
