@import '../../../styles/settings.scss';

.homeTiles {
	&__wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		gap: 1rem;
		padding: 0 3rem;
	}

	&__tile {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		box-shadow: $shadow-medium;

		img {
			display: flex;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform 0.3s $sine-out;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.25);
			z-index: 1;
			transition: background-color 0.3s $sine-out;
		}

		&:hover {
			img {
				transform: scale(1.025);
			}

			&::after {
				background-color: rgba(0, 0, 0, 0.4);
			}

			.homeTiles__tile__info {
				p:last-of-type {
					text-decoration-color: $white;
				}
			}
		}

		&__info {
			position: absolute;
			bottom: 3rem;
			left: 50%;
			width: 100%;
			transform: translateX(-50%);
			text-align: center;
			z-index: 2;

			p,
			h5 {
				color: $white;
			}

			p:last-of-type {
				text-decoration: underline 0.063rem rgba(0, 0, 0, 0);
				text-underline-offset: 0.5rem;
				transition: text-decoration-color 0.3s $sine-out;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.homeTiles {
		&__wrapper {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(3, 1fr);
			padding: 0 2rem;
		}
	}
}

@media screen and (max-width: 600px) {
	.homeTiles {
		&__wrapper {
			grid-template-columns: 1fr;
			grid-template-rows: repeat(6, 1fr);
			padding: 0 1rem;
		}
	}
}
