@import '../../../styles/settings.scss';

#container {
	display: flex;
	flex-direction: column;
	gap: 8rem;
}

@media screen and (max-width: 1200px) {
	#container {
		gap: 6rem;
	}
}

@media screen and (max-width: 600px) {
	#container {
		gap: 4rem;
	}
}
