@import '../../../styles/settings.scss';

.homeHero {
	&__wrapper {
		box-shadow: $shadow-medium;
		min-height: 100vh;
	}

	&__inner {
		position: relative;

		&__video {
			display: block;
			width: 100%;
			min-height: 100vh;
			object-fit: cover;
			z-index: -1;
			filter: brightness(0.6);
		}

		&__title {
			position: absolute;
			bottom: 15%;
			left: 10%;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			width: 28rem;

			h1 {
				font-weight: 200;
				color: $white;
			}

			p {
				color: $white;
				margin-bottom: 1rem;
			}

			a {
				font-size: 2.027rem;
				font-weight: 900;
				color: $white;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.homeHero {
		&__inner {
			&__title {
				left: 5rem;
				width: 22rem;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.homeHero {
		&__inner {
			&__title {
				left: 0;
				gap: 0;
				width: 100%;
				padding: 0 1rem;
				text-align: center;

				h1 {
					font-size: 1.802rem;
				}

				a {
					font-size: 1.802rem;
				}
			}
		}
	}
}
