@import '../../../styles/settings.scss';

.error {
	&__wrapper {
		text-align: center;
		width: 80%;
		padding: 2rem;

		p {
			color: $error;
		}
	}
}
