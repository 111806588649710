@import '../../../styles/settings.scss';

.productNew {
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		padding: 3rem;
		background-color: $black5;
		min-height: 150vh;
		overflow: hidden;
	}

	&__similar {
		margin: 0 -1rem;
	}
}

@media screen and (max-width: 1200px) {
	.productNew {
		&__wrapper {
			padding: 2rem;
		}
	}
}

@media screen and (max-width: 600px) {
	.productNew {
		&__wrapper {
			padding: 0;
			background-color: $white;
		}
	}
}
