@import '../../../styles/settings.scss';

.homeStores {
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		padding: 0 3rem 0 3rem;
	}

	&__inner {
		display: flex;
		gap: 3rem;
	}

	&__info {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		width: 25%;

		&__stores {
			display: flex;
			flex-direction: column;
			gap: 0.75rem;

			&__box {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				padding: 0.5rem 1rem;
				border: solid 0.063rem $black;
				cursor: pointer;
				transition: border 0.1s $sine-out;

				&:hover {
					border: solid 0.063rem $mint70;
				}

				&.box-active {
					box-shadow: $shadow-light;

					&:hover {
						border: solid 0.063rem $black;
					}
				}

				&__icon {
					opacity: 0;
					visibility: 0;
					transition:
						opacity 0.1s $sine-out,
						visibility 0.1s $sine-out;

					img {
						display: block;
						width: 1.5rem;
						height: 100%;
					}

					&.icon-active {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}

		&__link {
			width: 100%;
			padding: 0.5rem 1rem;
			background-color: $mint;
			border: solid 0.063rem $mint;
			text-align: center;
			box-shadow: $shadow-light;
			cursor: pointer;
			transition:
				background-color 0.3s $sine-out,
				border 0.3s $sine-out;

			p {
				font-weight: 800;
				color: $black75;
			}

			&:hover {
				background-color: $white;
				border: solid 0.063rem $black75;
			}
		}
	}

	&__image {
		width: 75%;
		max-height: 75vh;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: brightness(0.9);
		}
	}
}

@media screen and (max-width: 1200px) {
	.homeStores {
		&__wrapper {
			padding: 0 2rem 0 2rem;
		}

		&__inner {
			gap: 2rem;
		}

		&__info {
			&__stores {
				&__box {
					padding: 0.5rem;

					&__icon {
						transform: rotate(-90deg);
					}
				}
			}
		}

		&__image {
			max-height: none;
		}
	}
}

@media screen and (max-width: 600px) {
	.homeStores {
		&__wrapper {
			padding: 0 1rem;
		}

		&__inner {
			flex-direction: column-reverse;
		}

		&__info {
			width: 100%;

			&__stores {
				&__box {
					width: 100%;
					padding: 0.5rem 1rem;
					text-align: start;
				}
			}
		}

		&__image {
			width: 100%;
			height: 50vh;
			overflow-y: hidden;
		}
	}
}
