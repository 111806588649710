.productsList {
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		width: 100%;
		padding: 8rem 0;
		min-height: 100vh;

		h1 {
			text-transform: uppercase;
		}
	}
}
