.productInfo {
	&__wrapper {
		display: flex;
		gap: 2rem;
		height: 75vh;
	}
}

@media screen and (max-width: 1200px) {
	.productInfo {
		&__wrapper {
			flex-direction: column;
			gap: 3rem;
			height: auto;
		}
	}
}
