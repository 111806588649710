@import '../../../styles/settings.scss';

.navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 999;
	transform: translateY(-100%);
	transition: transform 0.3s $sine-out;

	&__wrapper {
		display: flex;
		flex-direction: column;
		padding: 0 3rem;
		background-color: $white;
		color: $black;
		transition:
			background-color 0.3s $sine-out,
			color 0.3s $sine-out;
	}

	&__body {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 5.75rem;

		&__left {
			display: none;
		}

		&__logo {
			font-size: 1.889rem;
			font-weight: 900;
			color: inherit;
		}
	}

	&__items {
		display: flex;
		align-items: center;
		gap: 2rem;
	}

	&__item {
		&__icon {
			display: flex;

			img {
				display: block;
				width: 1.5rem;
				height: 100%;
			}

			button {
				padding: 0;
				background-color: transparent;
				border: none;
				cursor: pointer;
			}
		}

		&__link {
			display: flex;
			align-items: center;
			height: 5.75rem;
			cursor: pointer;

			&__dropdown {
				position: fixed;
				top: 5.75rem;
				left: 0;
				display: flex;
				width: 100%;
				height: 60vh;
				background-color: $white;
				border-top: solid 0.063rem $black25;
				border-bottom: solid 0.063rem $black25;
				opacity: 0;
				visibility: hidden;
				transition: opacity 0.3s $sine-out;

				&__img {
					position: relative;
					width: 33%;
					height: 100%;
					padding: 2rem;

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}

				&__list {
					display: flex;
					width: 67%;

					&__items {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 100%;
						padding-top: 6rem;
						gap: 2rem;

						&__inner {
							display: flex;
							flex-direction: column;
							gap: 2rem;

							ul {
								display: flex;
								flex-direction: column;
								gap: 1rem;

								li {
									text-underline-offset: 0.5rem;
									text-decoration: underline 0.063rem rgba(0, 0, 0, 0);
									transition: text-decoration-color 0.3s $sine-out;

									a {
										text-decoration: none;
										color: $black;
									}

									&:hover {
										text-decoration-color: rgba(0, 0, 0, 0.5);
									}
								}
							}
						}

						&__pattern {
							height: 100%;
							width: 0.063rem;
							background-color: $black25;
						}
					}
				}
			}

			a,
			p {
				color: inherit;
				text-underline-offset: 0.5rem;
				text-decoration: underline 0.063rem rgba(0, 0, 0, 0);
				transition: text-decoration-color 0.3s $sine-out;
			}

			&:hover {
				.navbar__item__link__dropdown {
					visibility: visible;
					opacity: 1;
				}

				a {
					text-decoration-color: rgba(0, 0, 0, 0.5);
				}
			}
		}
	}

	&__search {
		position: fixed;
		top: 5.75rem;
		left: 0;
		width: 100vw;
		height: 100dvh;
		background-color: rgba(0, 0, 0, 0.6);
		opacity: 0;
		visibility: hidden;
		transition:
			opacity 0.3s $sine-out,
			visibility 0.3s $sine-out;

		&.active {
			visibility: visible;
			opacity: 1;
		}

		&__inner {
			visibility: hidden;
			transition: visibility 0.3s $sine-out;

			&.open {
				visibility: visible;
			}
		}

		&__form {
			display: flex;
			align-items: center;
			gap: 0.1rem;

			&__field {
				input {
					width: 7rem;
					font-family: $font;
					color: $black;
					background-color: transparent;
					border: none;
					border-bottom: solid 0.063rem $black;
					outline: none;
				}
			}

			&__button {
				padding: 0;
				background-color: transparent;
				border: none;
				cursor: pointer;

				img {
					width: 1.5rem;
					height: 100%;
					display: block;
				}
			}
		}
	}

	&__login,
	&__cart {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100dvh;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 299;
		opacity: 0;
		visibility: hidden;
		transition:
			opacity 0.3s $sine-out,
			visibility 0.3s $sine-out;

		&.active {
			visibility: visible;
			opacity: 1;
		}

		&__inner {
			transform: translateX(100%);
			visibility: hidden;
			transition:
				transform 0.3s $sine-out,
				visibility 0.3s $sine-out;

			&.open {
				visibility: visible;
				transform: translateX(0);
			}
		}
	}

	&__footer,
	&__modal {
		display: none;
	}
}

.nav-top {
	background-color: transparent;
	color: $white;

	.navbar__search__form {
		&__field {
			input {
				color: $white;
				border-bottom: solid 0.063rem $white;
			}
		}

		&__button {
			img {
				filter: invert(1);
			}
		}
	}

	.navbar__item__icon {
		img {
			filter: invert(1);
		}
	}

	.navbar__item__menu__button {
		&__up {
			background-color: $white;
		}

		&__down {
			background-color: $white;
		}
	}

	&:hover {
		background-color: $white;
		color: $black;

		.navbar__search__form {
			&__field {
				input {
					color: $black;
					border-bottom: solid 0.063rem $black;
				}
			}

			&__button {
				img {
					filter: invert(0);
				}
			}
		}

		.navbar__item__icon {
			img {
				filter: invert(0);
			}
		}
	}
}

.nav-open {
	transform: translateY(0);
}

.link-active {
	font-weight: 800;
}

.tablet__only {
	display: none;
}

@media screen and (max-width: 1200px) {
	.navbar {
		&__wrapper {
			padding: 0 2rem;
		}

		&__body {
			height: 4.25rem;

			&__center {
				display: none;
			}
		}

		&__items {
			gap: 1.2rem;
		}

		&__item__menu__button {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			padding: 0;
			background-color: transparent;
			border: none;
			cursor: pointer;

			&__up,
			&__down,
			&__up__modal__btn,
			&__down__modal__btn {
				width: 1.5rem;
				height: 0.1rem;
				background-color: $black;
				transition: transform 0.3s $sine-out;
			}

			&.active {
				.navbar__item__menu__button__up,
				.navbar__item__menu__button__up__modal__btn {
					transform: rotate(45deg);
				}

				.navbar__item__menu__button__down,
				.navbar__item__menu__button__down__modal__btn {
					transform: translateY(-0.55rem) rotate(-45deg);
				}
			}
		}

		&__modal {
			position: fixed;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100dvh;
			background-color: rgba(0, 0, 0, 0.6);
			z-index: 199;
			opacity: 0;
			visibility: hidden;
			transition:
				opacity 0.3s $sine-out,
				visibility 0.3s $sine-out;

			&__inner {
				position: fixed;
				top: 0;
				right: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 1rem;
				width: 60%;
				height: 100dvh;
				padding: 1.4rem 2rem;
				background-color: $white;
				transform: translateX(100%);
				visibility: hidden;
				transition:
					transform 0.3s $sine-out,
					visibility 0.3s $sine-out;

				&__scroll {
					display: flex;
					flex-direction: column;
					gap: 2rem;
					height: 90dvh;
					overflow: scroll;
					-ms-overflow-style: none;
					scrollbar-width: none;

					&::-webkit-scrollbar {
						display: none;
					}
				}

				&__header {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					&__button {
						img {
							display: block;
							width: 1.5rem;
							height: 100%;
						}
					}
				}

				&__links {
					display: flex;
					flex-direction: column;

					&__item {
						padding: 1rem 0;
						border-top: solid 0.063rem $black25;

						&:last-of-type {
							border-bottom: solid 0.063rem $black25;
						}

						&__link {
							display: flex;
							flex-direction: column;

							h4 {
								margin-top: 1.2rem;
							}

							&__button {
								display: flex;
								align-items: center;
								justify-content: space-between;
								width: 100%;
								padding-right: 1rem;

								img {
									width: 1rem;
									height: 100%;
									transform: rotate(0);
									transition: transform 0.3s $sine-out;

									&.rotate {
										transform: rotate(180deg);
									}
								}
							}
						}
					}
				}

				&__bottom {
					display: flex;
					flex-direction: column;
					gap: 2rem;
				}

				&__image {
					img {
						display: block;
						width: 100%;
						height: 100%;
					}
				}

				&__footer {
					display: flex;
					flex-direction: column;
					gap: 1.4rem;

					a {
						color: $black;
						opacity: 0.6;
					}
				}

				&.open {
					visibility: visible;
					transform: translateX(0);
				}
			}

			&.active {
				visibility: visible;
				opacity: 1;
			}
		}

		&__search {
			top: 4.25rem;
		}

		&__item__link__dropdown__list__items__inner {
			padding-left: 0.5rem;
			display: grid;
			grid-template-rows: 0fr;
			transition: grid-template-rows 0.3s $sine-out;

			& > ul {
				overflow: hidden;
			}

			&.list-open {
				grid-template-rows: 1fr;
			}

			ul {
				transform: translateY(1rem);
				opacity: 0;
				transition:
					transform 0.3s $sine-out,
					opacity 0.3s $sine-out;

				&.open {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}
	}

	.nav-top {
		&:hover {
			background-color: transparent;
			color: $white;

			.navbar__search__form {
				&__field {
					input {
						color: $white;
						border-bottom: solid 0.063rem $white;
					}
				}

				&__button {
					img {
						filter: invert(1);
					}
				}
			}

			.navbar__item__icon {
				img {
					filter: invert(1);
				}
			}
		}
	}

	.tablet__only {
		display: block;
	}
}

@media screen and (max-width: 600px) {
	.navbar {
		&__wrapper {
			padding: 0 1rem;
		}

		&__body {
			&__left {
				display: block;
			}

			&__logo {
				font-size: 1.563rem;
			}
		}

		&__items {
			gap: 1rem;
		}

		&__modal {
			&__inner {
				width: 100%;
				padding: 1.4rem 1rem;

				&__header {
					flex-direction: row-reverse;
				}
			}
		}

		&__footer {
			display: block;
			padding-bottom: 0.5rem;
		}

		&__search {
			top: 6.7rem;

			&__inner {
				transform: translateX(100%);
				visibility: hidden;
				transition:
					transform 0.3s $sine-out,
					visibility 0.3s $sine-out;

				&.open {
					visibility: visible;
					transform: translateX(0);
				}
			}

			&__form {
				position: relative;

				&__field {
					width: 100%;

					input {
						width: 100%;
						padding-right: 2rem;
						padding-bottom: 0.5rem;
					}
				}

				&__button {
					position: absolute;
					right: 0;
					top: 0;
				}
			}
		}
	}

	.desktop__only {
		display: none;
	}

	.tablet__only {
		display: none;
	}
}
